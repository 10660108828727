<template>
  <validation-observer ref="obs" v-slot="{ invalid, errors }">
    <v-card class="mb-4" outlined>
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("delivery") }}</v-toolbar-title>
        <v-spacer />

        <v-tooltip top v-if="showResetAddress && deliveryAddressDeleted">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="setDefaultDeliveryAddress"
              :disabled="
                loadingPreview || loadingReceipt || !differentDeliveryAddress
              "
              icon
            >
              <v-icon>mdi-restore</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("reset delivery address") }}</span>
        </v-tooltip>

        <v-tooltip top v-if="showDeleteAddress && !deliveryAddressDeleted">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="deleteDeliveryAddress"
              :disabled="
                loadingPreview || loadingReceipt || !differentDeliveryAddress
              "
              icon
            >
              <v-icon>mdi-delete-sweep-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("delete delivery address") }}</span>
        </v-tooltip>

        <v-tooltip top v-if="showReceiptPreview">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="previewReceipt"
              :disabled="loadingPreview || invalid"
              :loading="loadingPreview"
              icon
            >
              <v-icon>$PdfDownload</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("preview delivery receipt") }}</span>
        </v-tooltip>

        <v-dialog v-model="editReceipt" width="80%" v-if="showReceiptEdit">
          <template v-slot:activator="{ on }">
            <v-tooltip top v-on="on">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="
                    editReceipt = true;
                    getReceipt();
                  "
                  :disabled="loadingReceipt || invalid"
                  :loading="loadingReceipt"
                  icon
                >
                  <v-icon>mdi-file-document-edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit delivery receipt") }}</span>
            </v-tooltip>
          </template>
          <hardware-shop-delivery-receipt
            :receipt="deliveryReceipt"
            :loading="loadingReceipt"
            @receiptCanceled="editReceipt = false"
            @receiptReset="
              deliveryReceipt = '';
              getReceipt();
            "
            @receiptSaved="
              deliveryReceipt = $event;
              editReceipt = false;
              $emit('receiptSaved', deliveryReceipt);
            "
          />
        </v-dialog>
      </v-toolbar>

      <v-card-actions>
        <v-switch
          v-model="differentDeliveryAddress"
          :label="$t('change delivery address')"
        />
      </v-card-actions>

      <v-card-text>
        <address-names
          v-model="deliveryAddress"
          company-max-length="100"
          first-name-max-length="100"
          last-name-max-length="100"
          :last-name-rules="deliveryAddress.is_corporate ? '' : 'required'"
          :disabled="!differentDeliveryAddress"
          @input="emitInput($event)"
        />
      </v-card-text>
    </v-card>

    <v-card class="mb-2" outlined>
      <v-card-text>
        <address-postal
          v-model="deliveryAddress"
          additional-max-length="255"
          :zip-max-length="10"
          zip-required
          :city-max-length="100"
          city-required
          :street-max-length="100"
          street-rules="required"
          street-number-name="house_number"
          :street-number-max-length="10"
          street-number-rules="required"
          :disabled="!differentDeliveryAddress"
          @input="emitInput($event)"
        />
      </v-card-text>
    </v-card>
  </validation-observer>
</template>

<script>
import downloadFile from "@/utils/mixins/downloadFile";
import AddressContacts from "@/components/basics/AddressContacts";
import AddressPostal from "@/components/basics/AddressPostal";
import AddressNames from "@/components/basics/AddressNames";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import HardwareShopDeliveryReceipt from "@/components/orders/hardware/HardwareShopDeliveryReceipt";

const emptyDeliveryAddress = {
  is_corporate: null,
  company: "",
  company_name: "",
  first_name: "",
  last_name: "",
  salutation: "n",
  additional: "",
  street: "",
  street_number: "",
  house_number: "",
  zip: "",
  zip_code: "",
  city: "",
  phone: "",
  mobile: "",
  email: "",
};

export default {
  name: "HardwareShopDelivery",
  props: {
    value: {
      type: Object,
    },
    showDeleteAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
    showResetAddress: {
      type: Boolean,
      required: false,
      default: false,
    },
    showReceiptPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    showReceiptEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    reference: {
      type: String,
      required: false,
      default: null,
    },
    validationUrl: {
      type: String,
      required: false,
      default: "shop/hardware/validate",
    },
    validationData: {
      type: Object,
    },
    receipt: {
      type: String,
      required: false,
      default: "",
    },
    defaultAddress: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mixins: [downloadFile, showErrors, isMobile],
  components: {
    AddressContacts,
    AddressPostal,
    AddressNames,
    HardwareShopDeliveryReceipt,
  },
  data: () => ({
    loadingDefaultAddress: false,
    loadingReceipt: false,
    loadingPreview: false,
    differentDeliveryAddress: false,
    deliveryAddressDeleted: false,
    editReceipt: false,
    deliveryReceipt: "",
    deliveryAddress: {},
    defautDeliveryAddress: {},
  }),
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value != null)
          this.deliveryAddress = this.normalizeDeliveryAddress(value);
        else this.getDeliveryAddress();
      },
    },
    receipt: {
      immediate: true,
      handler(value) {
        this.deliveryReceipt = value || "";
      },
    },
    reference: {
      immediate: true,
      handler(value) {
        if (value != null) this.differentDeliveryAddress = true;
      },
    },
    differentDeliveryAddress(value) {
      if (value != null) this.getDeliveryAddress();
    },
    defaultAddress: {
      immediate: true,
      handler(value) {
        if (value) this.defaultDeliveryAddress = { ...value };
      },
    },
  },
  methods: {
    emitInput(event) {
      this.$emit("input", { ...this.value, ...event });
    },
    normalizeDeliveryAddress(value) {
      var addr = { ...value };
      addr.house_number = addr.house_number || addr.street_number;
      addr.zip = addr.zip || addr.zip_code;
      addr.company = addr.company || addr.company_name;

      addr.email = (addr.email || "").trim() || null;
      addr.phone = (addr.phone || "").trim() || null;
      addr.mobile = (addr.mobile || "").trim() || null;

      // quick hack to solve issue with company name and is_corporate
      addr.company = addr.is_corporate ? addr.company || null : null;

      // fix Mobile/SIP steet_number + zip_code :(
      addr.street_number = addr.house_number;
      addr.zip_code = addr.zip;

      return addr;
    },
    getDeliveryAddress() {
      if (this.$store.getters.isReady) {
        var that = this;

        if (this.reference == null) {
          if (this.defaultAddress != null)
            this.deliveryAddress = this.normalizeDeliveryAddress(
              this.defaultAddress
            );
          else
            this.deliveryAddress = this.normalizeDeliveryAddress(
              this.$store.getters.defaultDeliveryAddress || {}
            );
          this.defautDeliveryAddress = { ...this.deliveryAddress };
          this.deliveryAddressDeleted = false;
          this.emitInput(this.deliveryAddress);
        } else {
          // or reference order install address from validate
          this.loadingDefaultAddress = true;
          this.$http
            .post(this.validationUrl, {
              reference: this.reference,
              orders: [],
            })
            .then((response) => {
              that.deliveryAddress = this.normalizeDeliveryAddress(
                response.data.delivery_address
              );
              that.defautDeliveryAddress = { ...that.deliveryAddress };
              that.deliveryAddressDeleted = false;
              that.emitInput(that.deliveryAddress);
            })
            .catch((error) => {})
            .finally(function () {
              that.loadingDefaultAddress = false;
            });
        }
      }
    },
    deleteDeliveryAddress() {
      this.emitInput(this.normalizeDeliveryAddress(emptyDeliveryAddress));
      this.deliveryAddressDeleted = true;
    },
    setDefaultDeliveryAddress() {
      this.emitInput(this.defautDeliveryAddress);
      this.deliveryAddressDeleted = false;
    },
    previewReceipt() {
      var that = this;
      this.loadingPreview = true;
      this.$http
        .post(this.validationUrl, this.validationData)
        .then((response) => {
          try {
            this.downloadFile(
              this.validationUrl + "?format=pdf",
              "post",
              this.validationData,
              this.$i18n.t("delivery-receipt.pdf"),
              null,
              function () {
                that.loadingPreview = false;
              }
            );
          } catch (error) {
            that.loadingPreview = false;
            console.log(error);
          }
        })
        .catch((error) => {
          that.loadingPreview = false;
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        });
    },
    getReceipt() {
      if (this.deliveryReceipt == null || this.deliveryReceipt == "") {
        var that = this;
        this.loadingReceipt = true;
        this.$http
          .post(this.validationUrl, this.validationData)
          .then((response) => {
            that.deliveryReceipt = (
              response.data.delivery_receipt || ""
            ).trim();
            that.$emit("receiptSaved", that.deliveryReceipt);
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loadingReceipt = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "delivery": "Delivery",
    "change delivery address": "Change delivery address",
    "preview delivery receipt": "Preview delivery receipt",
    "edit delivery receipt": "Edit delivery receipt",
    "delivery contact": "Delivery contact",
    "delivery-receipt.pdf": "Delivery-receipt.pdf",
    "notifyMsg": "Your order has been successfully committed.",
    "delete delivery address": "Delete delivery address",
    "reset delivery address": "Reset delivery address"
  },
  "de": {
    "delivery": "Lieferung",
    "change delivery address": "Lieferadresse ändern",
    "preview delivery receipt": "Lieferschein Vorschau",
    "edit delivery receipt": "Lieferschein bearbeiten",
    "delivery contact": "Lieferkontakt",
    "delivery-receipt.pdf": "Lieferschein.pdf",
    "notifyMsg": "Ihre Bestellung wurde erfolgreich übermittelt.",
    "delete delivery address": "lösche Lieferadresse",
    "reset delivery address": "Lieferadresse zurücksetzen"
  }
}
</i18n>
